import React, { Component } from "react";
import { Link } from "react-router-dom";
import Dropworks from "./Dropworks";
import DropUserProfile from "./DropUserProfile";
import Dropresources from "./Dropresources";
import QuoteButtons from "./QuoteButtons";
import { openNav, closeNav } from "../../utils/common";
import { Auth } from "aws-amplify";
import UserIcon from "../../images/user-circle-regular.svg";
import { logout } from "../../utils/common";
import HeaderSubPart from "./HeaderSubPart";
let isMobile = window.innerWidth < 992;
class Navbar extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   userRole: [],
    //   userName: "",
    //   displayProfileMenu: false,
    //   displayQuotesMenu: false,
    //   displayQuoteButton: false,
    //   displayLoginButton: true,
    //   displayHowItWorks: false,
    //   displayResources: false,
    //   displayUnderWriterLink: false,
    //   displayComplianceLink: false,
    //   displayDashboard: false,
    // };
  }

  // componentDidMount() {
  //   let userRole = [];

  //   var curre = Auth.currentSession()
  //     .then((res) => {
  //       console.log("Current Authenticated Response User: ", res);
  //       userRole = res.accessToken.payload["cognito:groups"];
  //       let userName =
  //         res.idToken.payload.name || res.idToken.payload.email.split("@")[0];

  //       this.setState({
  //         userRole: userRole,
  //         userName: userName,
  //         userEmail: res.idToken.payload.email,
  //       });
  //       // console.log("Role: ", this.state.userRole);
  //       console.log("process: ", process);
  //       if (userName !== "" && userRole && userRole.length > 0) {
  //         // This is a user who has logged in.
  //         this.setState({
  //           displayLoginButton: false,
  //           displayProfileMenu: true,
  //           displayQuoteButton: false,
  //         });
  //         sessionStorage.setItem("isLoggedIn", true);
  //         // if (
  //         //   this.state.userRole.includes(
  //         //     process.env.REACT_APP_EXTENSIS_USER_GROUP
  //         //   )
  //         // ) {
  //         //   this.setState({
  //         //     displayQuotesMenu: true,
  //         //     displayDashboard: true,
  //         //   });
  //         // } else if (this.state.userRole.includes("Carrier")) {
  //         //   this.setState({
  //         //     displayHowItWorks: false,
  //         //     displayResources: false,
  //         //     displayUnderWriterLink: true,
  //         //     displayDashboard: true,
  //         //   });
  //         // } else if (
  //         //   this.state.userRole.includes("Compliance") ||
  //         //   this.state.userRole.includes("ComplianceAdmin")
  //         // ) {
  //         //   this.setState({
  //         //     displayHowItWorks: false,
  //         //     displayResources: false,
  //         //     displayComplianceLink: true,
  //         //   });
  //         // }
  //       } else {
  //         // This user could not log in.
  //         // No change in state required.
  //         console.log("User could not log in.");
  //         this.setState({
  //           displayHowItWorks: true,
  //           displayResources: true,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       if (err === "No current user") {
  //         console.log("Current Authenticated User: ", err);
  //       }
  //       this.setState({
  //         displayHowItWorks: true,
  //         displayResources: true,
  //       });
  //     });
  //   console.log("Current Authenticated User: ", curre);
  // }

  render() {
    const variant = this.props.variant;

    return (
      <div id="navbarMain" className="new-navbar container-fluid">
        <div>
          <div>
            <div className="h-100 justify-content-between navbar_responsive">
              <Link
                to={"/"}
                className="navbar-brand pride-logo d-flex align-items-center"
              >
                {process.env.REACT_APP_MODE === "beta" ? (
                  <span className="peo-header">
                    Professional
                    <br /> Employer
                    <br /> Organization
                  </span>
                ) : (
                  <img
                    className="extensis-image"
                    src={require("../../images/ExtensisHR.png")}
                    alt="Extensis Logo"
                  />
                )}
              </Link>
              <Link
                to={"/"}
                className="navbar-brand insurecomp-logo d-flex align-items-center"
              >
                <img
                  className="insurecomp-image"
                  src={require("../../images/new-logo.png")}
                  alt="InsureComp Logo"
                />
              </Link>
            </div>
          </div>
          {variant !== "large-logo" && (
            <div className="pb-2">
              <hr className="mt-0" />
              <div>{!isMobile ? <HeaderSubPart /> : " "}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Navbar;
